import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { ClickAwayListener } from '@mui/material';
import Box from '@mui/material/Box';
import { GamesDisplayFieldsFragment } from 'Graphql/games-display.fragment.generated';
import React, { useState } from 'react';
import { DemiplaneSmallLogo } from './DemiplaneSmallLogo';
import { StyledBox } from './NexusDemiplaneMenu.styles';
import { dynamicImport } from 'src/lib/dynamicImport';

const NexusDemiplaneDropDownMenu = dynamicImport(
  'NexusDemiplaneDropDownMenu',
  () =>
    import('../NexusDemiplaneDropDownMenu').then(
      (mod) => mod.NexusDemiplaneDropDownMenu
    ),
  { ssr: false }
);

interface NexusDemiplaneMenuProps {
  gamesDisplay?: GamesDisplayFieldsFragment;
}

export const NexusDemiplaneMenu = ({
  gamesDisplay,
}: NexusDemiplaneMenuProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!menuAnchorEl) {
      setMenuAnchorEl(event.currentTarget);
    } else {
      setMenuAnchorEl(null);
    }
  };

  const handleMainNavClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          handleMainNavClose();
        }}
      >
        <Box
          width={100}
          height={{ xs: 72, lg: 80 }}
          position='relative'
          bgcolor='transparent'
          zIndex={20}
          aria-label="demiplane's nexus menu button"
          data-cy='top-nav-nexus-demiplane-btn'
          onClick={handleClick}
          sx={{ cursor: 'pointer' }}
          tabIndex={0}
          role='menubutton '
          aria-expanded={!!menuAnchorEl}
          aria-haspopup='true'
          display={{ xs: 'none', md: 'block' }}
          border='none'
          component='button'
          className='top-nav-nexus-demiplane-btn'
        >
          <StyledBox />
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='100%'
            width='100%'
            position='relative'
            bgcolor='transparent'
            className='top-nav-nexus-demiplane-btn-inner'
          >
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              className='top-nav-nexus-demiplane-btn-logo-container'
            >
              <DemiplaneSmallLogo />
              {!!menuAnchorEl ? (
                <ArrowDropUpIcon display='flex' />
              ) : (
                <ArrowDropDownIcon display='flex' />
              )}
            </Box>
          </Box>
        </Box>
      </ClickAwayListener>
      {!!menuAnchorEl && (
        <NexusDemiplaneDropDownMenu
          anchorEl={menuAnchorEl}
          onClose={handleMainNavClose}
          gamesDisplay={gamesDisplay}
        />
      )}
    </>
  );
};
