import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common?.black,
  opacity: 0.5,
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
}));
