import React, { useCallback, useEffect, useState } from 'react';
import { MenuButton } from '../../Shared/MenuButton';
import { useRouter } from 'next/router';
import { useSourcesMenuUserEntitlementsQuery } from 'Graphql/sources-menu-user-entitlements.query.generated';
import { OperationResult, useClient } from 'urql';
import {
  IsProductKeyWaveEntitledDocument,
  IsProductKeyWaveEntitledQuery,
} from 'Graphql/is-product-key-wave-entitled.query.generated';
import { NexusMenuProductSourceFieldsFragment } from 'Components/NexusNavigation/NexusNavbar/Components/PathfinderNexusMenu/nexus-menu-product-source.fragment.generated';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useRxJsEmitter } from 'Hooks/RxJsEmitter';
import { NexusTopNavData } from 'src/lib/pageDataLoading/pageDataLoading';
import { dynamicImport } from 'src/lib/dynamicImport';

const PathfinderNexusMenu = dynamicImport(
  'PathfinderNexusMenu',
  () =>
    import(
      'src/components/NexusNavigation/NexusNavbar/Components/PathfinderNexusMenu'
    ).then((mod) => mod.PathfinderNexusMenu),
  { ssr: false }
);
const AvatarSourcesMenu = dynamicImport(
  'AvatarSourcesMenu',
  () =>
    import(
      'src/components/NexusNavigation/NexusNavbar/Components/AvatarSourcesMenu'
    ).then((mod) => mod.AvatarSourcesMenu),
  { ssr: false }
);
const MutantSourcesMenu = dynamicImport(
  'MutantSourcesMenu',
  () =>
    import(
      'src/components/NexusNavigation/NexusNavbar/Components/MutantSourcesMenu'
    ).then((mod) => mod.MutantSourcesMenu),
  { ssr: false }
);
const AlienSourcesMenu = dynamicImport(
  'AlienSourcesMenu',
  () =>
    import(
      'src/components/NexusNavigation/NexusNavbar/Components/AlienSourcesMenu'
    ).then((mod) => mod.AlienSourcesMenu),
  { ssr: false }
);
const VampireSourcesMenu = dynamicImport(
  'VampireSourcesMenu',
  () =>
    import(
      'src/components/NexusNavigation/NexusNavbar/Components/VampireSourcesMenu'
    ).then((mod) => mod.VampireSourcesMenu),
  { ssr: false }
);
const HunterSourcesMenu = dynamicImport(
  'HunterSourcesMenu',
  () =>
    import(
      'src/components/NexusNavigation/NexusNavbar/Components/HunterSourcesMenu'
    ).then((mod) => mod.HunterSourcesMenu),
  { ssr: false }
);
const NexusGeneralSourceMenu = dynamicImport(
  'NexusGeneralSourceMenu',
  () =>
    import(
      'src/components/NexusNavigation/NexusNavbar/Components/NexusGeneralSourceMenu'
    ).then((mod) => mod.NexusGeneralSourceMenu),
  { ssr: false }
);

interface IValidProduct {
  sourceName: string;
  sourceId: string;
  sourceSlug?: string;
  nexusAssetSlug: string;
  productId: string;
  state?: string;
  demiplaneSlug: string | null | undefined;
  productBundle: boolean;
  type?: string;
  isEntitled: boolean;
}

interface NexusLibraryMenuProps {
  buttonStyle?: any;
  arrowColor?: string;
  topNav: NexusTopNavData;
}

export const NexusLibraryMenu = ({
  buttonStyle,
  arrowColor,
  topNav,
}: NexusLibraryMenuProps) => {
  const { classPrimer, pathfinderProducts, nexus, products } = topNav;
  const { listen } = useRxJsEmitter();
  const router = useRouter();
  const client = useClient();
  const { nexusSlug } = router.query;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sourcesArrowClicked, setSourcesArrowClicked] = useState(false);
  const [sourcesMenuPopperOpen, setSourcesMenuPopperOpen] = useState(false);
  const [validRulebooks, setValidRulebooks] = useState<IValidProduct[]>([]);
  const [validLostOmens, setValidLostOmens] = useState<IValidProduct[]>([]);
  const [validStandaloneAdventures, setValidStandaloneAdventures] = useState<
    IValidProduct[]
  >([]);
  const [validProducts, setValidProducts] = useState<IValidProduct[]>([]);
  const [nexusAssetSlug, setNexusAssetSlug] = useState<string | undefined>();
  const [sourceLoading, setSourceLoading] = useState<boolean>(false);
  const [productSourceIds, setProductSourceIds] = useState<string[]>([]);
  const [ruleBookSourceIds, setRulebookSourceIds] = useState<string[]>([]);
  const [lostOmensSourceIds, setLostOmensSourceIds] = useState<string[]>([]);
  const [standAloneSourceIds, setStandAloneSourceIds] = useState<string[]>([]);

  const [firstCategoryName, setFirstCategoryName] = useState<
    string | undefined
  >();

  const [pf2eRulebookEntitlementResult, refresPf2eRulebookhEntitlements] =
    useSourcesMenuUserEntitlementsQuery({
      variables: {
        category: 'source',
        ids: ruleBookSourceIds,
      },
      pause: !ruleBookSourceIds.length,
      requestPolicy: 'network-only',
    });
  const {
    data: pf2eRulebookEntitlementData,
    fetching: pf2eRulebookEntitlementDataLoading,
  } = pf2eRulebookEntitlementResult;

  const [pf2eLostOmensEntitlementResult, refreshPf2eLostOmensEntitlements] =
    useSourcesMenuUserEntitlementsQuery({
      variables: {
        category: 'source',
        ids: lostOmensSourceIds,
      },
      pause: !lostOmensSourceIds.length,
      requestPolicy: 'network-only',
    });
  const {
    data: pf2eLostOmensEntitlementData,
    fetching: pf2eLostOmensEntitlementDataLoading,
  } = pf2eLostOmensEntitlementResult;

  const [pf2eStandAloneEntitlementResult, refresPf2eStandAlonehEntitlements] =
    useSourcesMenuUserEntitlementsQuery({
      variables: {
        category: 'source',
        ids: standAloneSourceIds,
      },
      pause: !standAloneSourceIds.length,
      requestPolicy: 'network-only',
    });
  const {
    data: pf2eStandAloneEntitlementData,
    fetching: pf2eStandAloneEntitlementDataLoading,
  } = pf2eStandAloneEntitlementResult;

  const [entitlementResult, refreshEntitlements] =
    useSourcesMenuUserEntitlementsQuery({
      variables: {
        category: 'source',
        ids: productSourceIds,
      },
      pause: !productSourceIds.length,
      requestPolicy: 'network-only',
    });

  const { data: entitlementData, fetching: entitlementDataLoading } =
    entitlementResult;

  const findSource = useCallback(
    async (source: NexusMenuProductSourceFieldsFragment) => {
      // Reusable function to check source entitlement based on state
      let found = false;

      const pathfinderRulebookEntitlement =
        pf2eRulebookEntitlementData?.slsUserEntitlements?.data.find(
          (e) => e.id === source.id
        );

      const pathfinderLostOmensEntitlement =
        pf2eLostOmensEntitlementData?.slsUserEntitlements?.data.find(
          (e) => e.id === source.id
        );

      const pathfinderStandAloneEntitlement =
        pf2eStandAloneEntitlementData?.slsUserEntitlements?.data.find(
          (e) => e.id === source.id
        );

      const pathfinderEntitlement =
        pathfinderRulebookEntitlement ??
        pathfinderLostOmensEntitlement ??
        pathfinderStandAloneEntitlement;

      const entitlement = entitlementData?.slsUserEntitlements?.data.find(
        (e) => e.id === source.id
      );

      if (
        source.source_state.name === 'ACTIVE' ||
        source.source_state.name === 'PREORDER'
      ) {
        found = true;
      } else if (source.source_state.name === 'EARLY_ACCESS') {
        const isWaveEntitledResult: OperationResult<IsProductKeyWaveEntitledQuery> =
          await client
            .query(IsProductKeyWaveEntitledDocument, {
              sourceIds: [source.id],
              earlyAccessEnabled: true,
              preorderAccessEnabled: false,
            })
            .toPromise();

        if (!!isWaveEntitledResult.error) {
          console.log(isWaveEntitledResult.error);
        }

        if (
          !!isWaveEntitledResult.data?.isProductKeyWaveEntitled?.data?.entitled
        ) {
          found = true;
        }
      } else if (source.source_state.name === 'ARCHIVED') {
        // if state is archived, but user owns it, then we'll show
        // otherwise hide it.
        if (entitlement?.owned.userStatus) {
          found = true;
        }
      }
      return {
        found,
        entitlement:
          nexusSlug === 'pathfinder2e' ? pathfinderEntitlement : entitlement,
      };
    },
    [
      pf2eRulebookEntitlementData?.slsUserEntitlements?.data,
      pf2eLostOmensEntitlementData?.slsUserEntitlements?.data,
      pf2eStandAloneEntitlementData?.slsUserEntitlements?.data,
      entitlementData?.slsUserEntitlements?.data,
      nexusSlug,
      client,
    ]
  );

  useEffect(() => {
    const setupPathfinderProducts = async () => {
      if (nexusSlug === 'pathfinder2e' && pathfinderProducts.length >= 1) {
        const sourceProductCategory = pathfinderProducts[0];

        setSourceLoading(true);

        const coreRule = sourceProductCategory.productCategoriesByParentId.find(
          (pc) => pc.name === 'rulebooks'
        );
        const lostOmens =
          sourceProductCategory.productCategoriesByParentId.find(
            (pc) => pc.name === 'lost omens'
          );
        const standaloneAdventures =
          sourceProductCategory.productCategoriesByParentId.find(
            (pc) => pc.name === 'standalone adventures'
          );

        if (!!coreRule) {
          const _validRulebooks: IValidProduct[] = [];

          for (const ppc of coreRule.product_product_categories) {
            if (!ppc.product.bundle) {
              const source = ppc.product.source;

              if (!!source) {
                const result = await findSource(source);
                if (
                  result.found &&
                  !_validRulebooks.find((vp) => vp.sourceId === source.id)
                ) {
                  _validRulebooks.push({
                    sourceName: ppc.product.name,
                    sourceId: source.id ?? undefined,
                    sourceSlug: ppc.product.demiplane_slug ?? undefined,
                    nexusAssetSlug: ppc.product.nexus_asset_slug ?? '',
                    productId: ppc.product.id,
                    state: source.source_state.name,
                    demiplaneSlug: ppc.product.demiplane_slug,
                    productBundle: ppc.product.bundle,
                    isEntitled: result.entitlement?.owned.userStatus ?? false,
                  });
                }
              }
            }
          }
          setValidRulebooks(_validRulebooks);
          setNexusAssetSlug(nexus?.asset_slug);
        }

        if (!!lostOmens) {
          let _validLostOmens: IValidProduct[] = [];

          for (const ppc of lostOmens.product_product_categories) {
            if (!ppc.product.bundle) {
              const source = ppc.product.source;

              if (!!source) {
                const result = await findSource(source);

                if (
                  result.found &&
                  !_validLostOmens.find((vp) => vp.sourceId === source.id)
                ) {
                  _validLostOmens.push({
                    sourceName: ppc.product.name,
                    sourceId: source.id ?? undefined,
                    sourceSlug: ppc.product.demiplane_slug ?? undefined,
                    nexusAssetSlug: ppc.product.nexus_asset_slug ?? '',
                    productId: ppc.product.id,
                    state: source.source_state.name,
                    demiplaneSlug: ppc.product.demiplane_slug,
                    productBundle: ppc.product.bundle,
                    isEntitled: !!result.entitlement?.owned.userStatus,
                  });
                }
              }
            }
          }

          setValidLostOmens(_validLostOmens);
        }

        if (!!standaloneAdventures) {
          const _validStandaloneAdventures: IValidProduct[] = [];

          for (const ppc of standaloneAdventures.product_product_categories) {
            if (!ppc.product.bundle) {
              const source = ppc.product.source;

              if (!!source) {
                const result = await findSource(source);
                if (
                  result.found &&
                  !_validStandaloneAdventures.find(
                    (vp) => vp.sourceId === source.id
                  )
                ) {
                  _validStandaloneAdventures.push({
                    sourceName: ppc.product.name,
                    sourceId: source.id ?? undefined,
                    sourceSlug: ppc.product.demiplane_slug ?? undefined,
                    nexusAssetSlug: ppc.product.nexus_asset_slug ?? '',
                    productId: ppc.product.id,
                    state: source.source_state.name,
                    demiplaneSlug: ppc.product.demiplane_slug,
                    productBundle: ppc.product.bundle,
                    isEntitled: !!result.entitlement?.owned.userStatus,
                  });
                }
              }
            }
          }

          setValidStandaloneAdventures(_validStandaloneAdventures);
        }

        setSourceLoading(false);
      }
    };
    setupPathfinderProducts();
  }, [
    client,
    pf2eLostOmensEntitlementDataLoading,
    pf2eLostOmensEntitlementData,
    pf2eRulebookEntitlementDataLoading,
    pf2eRulebookEntitlementData,
    pf2eStandAloneEntitlementDataLoading,
    pf2eStandAloneEntitlementData,
    findSource,
    nexusSlug,
    pathfinderProducts,
    nexus?.asset_slug,
  ]);

  useEffect(() => {
    if (nexusSlug === 'pathfinder2e') {
      const sourceProductCategory = pathfinderProducts[0];

      const coreRule = sourceProductCategory.productCategoriesByParentId.find(
        (pc) => pc.name === 'rulebooks'
      );
      const lostOmens = sourceProductCategory.productCategoriesByParentId.find(
        (pc) => pc.name === 'lost omens'
      );
      const standaloneAdventures =
        sourceProductCategory.productCategoriesByParentId.find(
          (pc) => pc.name === 'standalone adventures'
        );

      const ruleBookSourceIds = coreRule?.product_product_categories
        .filter((prodCat) => !!prodCat.product.source)
        .map((prodCat) => {
          return prodCat.product.source!.id;
        });

      const lostOmensSourceIds = lostOmens?.product_product_categories
        .filter((prodCat) => !!prodCat.product.source)
        .map((prodCat) => {
          return prodCat.product.source!.id;
        });

      const standAloneSourceIds =
        standaloneAdventures?.product_product_categories
          .filter((prodCat) => !!prodCat.product.source)
          .map((prodCat) => {
            return prodCat.product.source!.id;
          });

      setRulebookSourceIds(ruleBookSourceIds ?? []);
      setLostOmensSourceIds(lostOmensSourceIds ?? []);
      setStandAloneSourceIds(standAloneSourceIds ?? []);
    }

    if (nexusSlug !== 'pathfinder2e') {
      const productSourceIds: string[] = [];

      const sourceProductCategory = products[0];

      const allCategories =
        sourceProductCategory?.productCategoriesByParentId.filter(
          (cat: any) => cat.name === 'sources'
        )[0]
          ? sourceProductCategory.productCategoriesByParentId.filter(
              (cat: any) => cat.name === 'sources'
            )[0].productCategoriesByParentId
          : [];

      for (const cat of allCategories) {
        for (const ppc of cat.product_product_categories) {
          if (!!ppc.product.source?.id) {
            productSourceIds.push(ppc.product.source?.id);
          }
        }
      }

      setProductSourceIds(productSourceIds);
    }
  }, [nexusSlug, pathfinderProducts, products]);

  useEffect(() => {
    const setupProducts = async () => {
      if (nexusSlug !== 'pathfinder2e' && products.length > 0) {
        const sourceProductCategory = products[0];

        setSourceLoading(true);

        const allCategories =
          sourceProductCategory.productCategoriesByParentId.filter(
            (cat: any) => cat.name === 'sources'
          )[0].productCategoriesByParentId;
        const _validProducts: IValidProduct[] = [];

        for (const cat of allCategories) {
          for (const ppc of cat.product_product_categories) {
            if (!ppc.product.bundle) {
              const source = ppc.product.source;

              if (!!source) {
                const result = await findSource(source);
                if (
                  result.found &&
                  !_validProducts.find((vp) => vp.sourceId === source.id)
                ) {
                  _validProducts.push({
                    sourceName: ppc.product.name,
                    sourceId: source.id ?? undefined,
                    sourceSlug: ppc.product.demiplane_slug ?? undefined,
                    nexusAssetSlug: ppc.product.nexus_asset_slug ?? '',
                    productId: ppc.product.id,
                    state: source.source_state.name,
                    demiplaneSlug: ppc.product.demiplane_slug,
                    productBundle: ppc.product.bundle,
                    isEntitled: !!result.entitlement?.owned.userStatus,
                  });
                }
              }
            }
          }
        }
        setValidProducts(_validProducts);
        setNexusAssetSlug(`${nexus?.asset_slug}`);
        setFirstCategoryName(
          !!allCategories && allCategories.length > 0
            ? allCategories[0].name
            : ''
        );
        setSourceLoading(false);
      }
    };
    setupProducts();
  }, [
    client,
    entitlementData,
    entitlementDataLoading,
    findSource,
    nexus?.asset_slug,
    nexusSlug,
    products,
  ]);

  useEffect(() => {
    const sub = listen('reloadEntitlements', () => {
      refresPf2eRulebookhEntitlements({
        variables: {
          category: 'source',
          ids: ruleBookSourceIds,
        },
        pause: !ruleBookSourceIds?.length,
        requestPolicy: 'network-only',
      });

      refreshPf2eLostOmensEntitlements({
        variables: {
          category: 'source',
          ids: lostOmensSourceIds,
        },
        pause: !lostOmensSourceIds?.length,
        requestPolicy: 'network-only',
      });
      refresPf2eStandAlonehEntitlements({
        variables: {
          category: 'source',
          ids: standAloneSourceIds,
        },
        pause: !standAloneSourceIds?.length,
        requestPolicy: 'network-only',
      });

      refreshEntitlements({
        variables: {
          category: 'source',
          ids: productSourceIds,
        },
        pause: !productSourceIds.length,
        requestPolicy: 'network-only',
      });
    });
    return () => {
      sub.unsubscribe();
    };
  }, [
    listen,
    lostOmensSourceIds,
    productSourceIds,
    refresPf2eRulebookhEntitlements,
    refresPf2eStandAlonehEntitlements,
    refreshEntitlements,
    refreshPf2eLostOmensEntitlements,
    ruleBookSourceIds,
    standAloneSourceIds,
  ]);

  const handleLibraryClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setSourcesMenuPopperOpen(true);
    setSourcesArrowClicked(!sourcesArrowClicked);
  };

  const handleLibraryClose = () => {
    setAnchorEl(null);
    setSourcesMenuPopperOpen(false);
    setSourcesArrowClicked(false);
  };

  const constructMenuElements = () => {
    switch (nexusSlug) {
      case 'pathfinder2e': {
        return (
          <>
            {anchorEl && (
              <PathfinderNexusMenu
                menuName='sources'
                anchorEl={anchorEl}
                handleClose={handleLibraryClose}
                open={sourcesMenuPopperOpen}
                onClickAway={() => handleLibraryClose()}
                rulebooks={validRulebooks}
                lostOmens={validLostOmens}
                standaloneAdventures={validStandaloneAdventures}
                productsLoading={sourceLoading}
                nexusAssetSlug={nexusAssetSlug ?? ''}
                classPrimer={classPrimer}
              />
            )}
          </>
        );
      }

      case 'vampire': {
        return (
          <>
            {anchorEl && (
              <VampireSourcesMenu
                anchorEl={anchorEl}
                handleClose={handleLibraryClose}
                open={sourcesMenuPopperOpen}
                onClickAway={() => handleLibraryClose()}
                products={validProducts}
                productsLoading={sourceLoading}
                nexusAssetSlug={nexusAssetSlug ?? ''}
                firstCategoryName={firstCategoryName}
              />
            )}
          </>
        );
      }

      case 'hunter': {
        return (
          <>
            {anchorEl && (
              <HunterSourcesMenu
                anchorEl={anchorEl}
                handleClose={handleLibraryClose}
                open={sourcesMenuPopperOpen}
                onClickAway={() => handleLibraryClose()}
                products={validProducts}
                productsLoading={sourceLoading}
                nexusAssetSlug={nexusAssetSlug ?? ''}
              />
            )}
          </>
        );
      }

      case 'mutantyearzero': {
        return (
          <>
            {anchorEl && (
              <MutantSourcesMenu
                anchorEl={anchorEl}
                handleClose={handleLibraryClose}
                open={sourcesMenuPopperOpen}
                onClickAway={() => handleLibraryClose()}
                products={validProducts}
                productsLoading={sourceLoading}
                nexusAssetSlug={nexusAssetSlug ?? ''}
              />
            )}
          </>
        );
      }

      case 'alienrpg': {
        return (
          <>
            {anchorEl && (
              <AlienSourcesMenu
                anchorEl={anchorEl}
                handleClose={handleLibraryClose}
                open={sourcesMenuPopperOpen}
                onClickAway={() => handleLibraryClose()}
                products={validProducts}
                productsLoading={sourceLoading}
                nexusAssetSlug={nexusAssetSlug ?? ''}
              />
            )}
          </>
        );
      }

      case 'marvelrpg': {
        return (
          <>
            {anchorEl && (
              <NexusGeneralSourceMenu
                anchorEl={anchorEl}
                handleClose={handleLibraryClose}
                open={sourcesMenuPopperOpen}
                onClickAway={() => handleLibraryClose()}
                products={validProducts}
                productsLoading={sourceLoading}
                nexusAssetSlug={nexusAssetSlug ?? ''}
              />
            )}
          </>
        );
      }

      case 'avatarlegends': {
        return (
          <>
            {anchorEl && (
              <AvatarSourcesMenu
                anchorEl={anchorEl}
                handleClose={handleLibraryClose}
                open={sourcesMenuPopperOpen}
                onClickAway={() => handleLibraryClose()}
                products={validProducts}
                productsLoading={sourceLoading}
                nexusAssetSlug={nexusAssetSlug ?? ''}
              />
            )}
          </>
        );
      }

      default: {
        return (
          <>
            {anchorEl && (
              <NexusGeneralSourceMenu
                anchorEl={anchorEl}
                handleClose={handleLibraryClose}
                open={sourcesMenuPopperOpen}
                onClickAway={() => handleLibraryClose()}
                products={validProducts}
                productsLoading={sourceLoading}
                nexusAssetSlug={nexusAssetSlug ?? ''}
              />
            )}
          </>
        );
      }
    }
  };

  return (
    <>
      <MenuButton
        role='menubutton'
        aria-label='library'
        aria-expanded={!!anchorEl}
        aria-haspopup='true'
        sx={{ mx: 1.5 }}
        endIcon={
          !!anchorEl ? (
            <ArrowDropUpIcon sx={{ color: arrowColor ?? 'demi.blue' }} />
          ) : (
            <ArrowDropDownIcon sx={{ color: arrowColor ?? 'demi.blue' }} />
          )
        }
        onClick={handleLibraryClick}
        buttonStyle={buttonStyle}
        data-cy='top-nav-nexus-library-btn'
        className={
          !!anchorEl
            ? 'top-nav-nexus-library-btn-active'
            : 'top-nav-nexus-library-btn'
        }
      >
        Library
      </MenuButton>

      {constructMenuElements()}
    </>
  );
};
