import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';

interface IStyleProps {
  backgroundColor: string;
  opacity: number;
}

export const useStyles = makeStyles((theme: Theme) => ({
  content: {
    backgroundColor: (props: IStyleProps) => props.backgroundColor,
    position: 'relative',
    width: '100%',
  },
  backgroundImage: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  headerContainer: {
    height: '2rem',
    width: 'inherit',
    whiteSpace: 'nowrap',
  },
  linksColumn: {
    width: '7.75rem',
  },
  linksColumnSmall: {
    position: 'relative',
    marginTop: '2rem',
    marginLeft: '2rem',
  },
  bottomBar: {
    width: '100%',
    position: 'relative',
  },
  bottomBarSmall: {
    display: 'block',
    width: '100%',
    position: 'relative',
  },
  bottomBarSmallLineOne: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    justifyContent: 'space-between',
  },
  bottomBarSmallLineTwo: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    justifyContent: 'center',
  },
  bottomBarMobile: {
    display: 'flex',
    height: '8rem',
    width: '100%',
    backgroundColor: '#000000',
  },
  headerText: {
    position: 'relative',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '100%',
    paddingTop: 8,
    paddingBottom: 8,
  },
  socialHeader: {
    display: 'flex',
  },
  socialContainer: {
    position: 'relative',
    marginLeft: '2rem',
    marginBottom: '2rem',
    marginTop: '2rem',
  },
  socialContainerSmall: {
    position: 'relative',
    marginTop: '2rem',
    marginLeft: '2rem',
    marginBottom: '2rem',
  },
  socialIcon: {
    marginRight: '1.5rem',
    height: '1.5rem',
    width: '1.5rem',
    color: theme.palette.common.white,
  },
  socials: {
    display: 'flex',
  },
  socialsSmall: {
    display: 'block',
  },
  linkText: {
    cursor: 'pointer',
    marginRight: '1rem',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '16px',
    paddingTop: 8,
    paddingBottom: 8,
  },
  tradeMarkText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '100%',
    color: '#FDFDFD',
  },
  supportContainer: {
    display: 'flex',
    position: 'absolute',
    right: '2rem',
    top: '1.75rem',
  },
  supportContainerSmall: {
    display: 'flex',
  },
  supportMobile: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly',
  },
  bottomRow1: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  bottomRow2: {
    width: '100%',
    display: 'flex',
  },
  supportText: {
    cursor: 'pointer',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
    marginLeft: '1rem',
    textDecoration: 'none',
    color: '#D9D6CD',
  },
  tradeMarkContainer: {
    marginLeft: '2rem',
    marginTop: '1.75rem',
  },
  tradeMarkContainerSmall: {
    marginRight: '2rem',
    marginTop: '1.75rem',
  },
  bottomBarLogoContainer: {
    textAlign: 'left',
    width: '13rem',
    height: '4rem',
  },
  nexusLogoContainer: {
    height: '4rem',
    position: 'relative',
    top: '2rem',
    left: '2rem',
    width: '10rem',
  },
  nexusLogoContainerSmall: {
    position: 'relative',
    paddingTop: '2rem',
    marginLeft: '2rem',
  },
  nexusLogo: {
    width: '10rem',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    cursor: 'pointer',
  },
  bottomBarLogo: {
    height: 'inherit',
    width: 'inherit',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    marginLeft: '1rem',
    cursor: 'pointer',
  },
  rightLinksContainer: {
    position: 'absolute',
    right: '2rem',
    top: '2rem',
    display: 'flex',
  },
  legal: {
    color: '#BFBFBF',
    fontStyle: 'italic',
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    textAlign: 'center',
    whiteSpace: 'break-spaces',
    maxWidth: '1000px',
    display: 'flex',
    justifyContent: 'center',
  },
  maxWidthContainer: {
    maxWidth: 1900,
    background: 'transparent',
    margin: '0 auto',
    position: 'relative',
  },
}));
