import React from 'react';

interface DiscordIconProps {
  height?: string;
  width?: string;
}

export const DiscordIcon = ({ height, width }: DiscordIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.7123 20C17.7123 20 16.9726 18.9762 16.3562 18.0714C19.0479 17.1905 20.0753 15.2381 20.0753 15.2381C19.2329 15.881 18.4315 16.3334 17.7123 16.6429C16.6849 17.1429 15.6986 17.4762 14.7329 17.6667C12.7603 18.0953 10.9521 17.9762 9.41096 17.6429C8.23973 17.381 7.23288 17 6.39041 16.6191C5.91781 16.4048 5.40411 16.1429 4.89041 15.8096C4.85959 15.7858 4.82877 15.7679 4.79795 15.75C4.76712 15.7322 4.7363 15.7143 4.70548 15.6905C4.66438 15.6667 4.64384 15.6429 4.62329 15.6191C4.25342 15.381 4.04795 15.2143 4.04795 15.2143C4.04795 15.2143 5.03425 17.1191 7.64383 18.0238C7.0274 18.9286 6.26712 20 6.26712 20C1.72603 19.8333 0 16.381 0 16.381C0 8.7144 2.9589 2.50017 2.9589 2.50017C5.91781 -0.07123 8.73288 0.000197861 8.73288 0.000197861L8.93836 0.285909C5.23973 1.52399 3.53425 3.40493 3.53425 3.40493C3.53425 3.40493 3.9863 3.11921 4.74657 2.71446C6.9452 1.59542 8.69178 1.2859 9.41096 1.21447L9.45703 1.20545C9.56068 1.185 9.65265 1.16685 9.76027 1.16685C11.0137 0.976379 12.4315 0.92876 13.911 1.11923C15.863 1.38114 17.9589 2.0478 20.0959 3.40493C20.0959 3.40493 18.4726 1.61923 14.9795 0.381146L15.2671 0.000197861C15.2671 0.000197861 18.0822 -0.07123 21.0411 2.50017C21.0411 2.50017 24 8.7144 24 16.381C24 16.381 22.2534 19.8333 17.7123 20ZM8.7366 9.31339C7.5746 9.31339 6.65723 10.5391 6.65723 12.0344C6.65723 13.5298 7.59498 14.7555 8.7366 14.7555C9.8986 14.7555 10.816 13.5298 10.816 12.0344C10.8364 10.5391 9.8986 9.31339 8.7366 9.31339ZM14.0977 12.0344C14.0977 10.5391 15.015 9.31339 16.177 9.31339C17.339 9.31339 18.2564 10.5391 18.2564 12.0344C18.2564 13.5298 17.339 14.7555 16.177 14.7555C15.0354 14.7555 14.0977 13.5298 14.0977 12.0344Z'
      fill='white'
    />
  </svg>
);
