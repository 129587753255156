import * as Types from './types';

import { gql } from 'urql';
export type NexusByNexusSlugFieldsFragment = { __typename?: 'demiplane_nexus', id: string, name: string, active: boolean, asset_slug: string, slug: string, footer_data?: any | null, listing_data?: any | null, library_primer_data?: any | null, call_to_action_data?: any | null, config_data?: any | null, is_publisher: boolean, game_type: { __typename?: 'demiplane_game_type', id: string }, element_category_route_mappings: Array<{ __typename?: 'demiplane_element_category_route_mapping', id: number, display_name?: string | null, element_category: string }> };

export const NexusByNexusSlugFieldsFragmentDoc = gql`
    fragment NexusByNexusSlugFields on demiplane_nexus {
  id
  name
  active
  asset_slug
  slug
  footer_data
  listing_data
  library_primer_data
  call_to_action_data
  config_data
  is_publisher
  game_type {
    id
  }
  element_category_route_mappings(
    where: {_or: [{listing_enabled: {_eq: true}}, {library_enabled: {_eq: true}}]}
  ) {
    id
    display_name
    element_category
  }
}
    `;