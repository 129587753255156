import { Container, SxProps, Toolbar } from '@mui/material';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import Image from 'next/image';
import Link from 'next/link';
import logoImage from 'public/logo/demiplane.png';
import { useEffect, useState } from 'react';
import { NexusTopNavData } from 'src/lib/pageDataLoading/pageDataLoading';
import { topNavTheme } from 'src/theme/demiplaneTheme';
import { UserButtons } from '../../Shared/UserButtons';
import { NexusDemiplaneMenu } from '../NexusDemiplaneMenu';
import { NexusMobileMenu } from '../NexusMobileMenu';
import { NexusNavigationButtons } from '../NexusNavigationButtons';
import { StyledAppBar, StyledImage } from './NexusTopNavigation.styles';
import { imgOpLoader } from '@demiplane-dev/ui-components/src/image-optimization-loader';

interface NexusTopNavigationProps {
  topNav: NexusTopNavData;
}

const NexusTopNavigationBase = ({ topNav }: NexusTopNavigationProps) => {
  const { nexus, gamesDisplays, currentUser, isCommerceEnabled } = topNav;

  const [navBarConfig, setNavVarConfig] = useState<any>();
  const [nexusBackgroundImage, setNexusBackgroundImage] = useState<string>();
  const [nexusLogo, setNexusLogo] = useState<string>();
  const [nexusButtonStyle, setNexusButtonStyle] = useState<SxProps>();
  const [nexusArrowColor, setNexusArrowColor] = useState<string>();
  const [showSearch, setShowSearch] = useState<boolean>(false);

  useEffect(() => {
    if (!!nexus?.config_data) {
      const nexusConfigData = nexus.config_data;

      const navBarConfig = nexusConfigData?.topNav;

      setNavVarConfig(navBarConfig);

      if (!!navBarConfig?.logo) {
        setNexusLogo(
          `${process.env.NEXT_PUBLIC_CONTENT_ENDPOINT_TRANSFORMED}${navBarConfig?.logo}`
        );
      }

      if (!!navBarConfig?.background) {
        setNexusBackgroundImage(
          `${process.env.NEXT_PUBLIC_CONTENT_ENDPOINT_TRANSFORMED}${navBarConfig?.background.desktop}`
        );
      }

      if (!!navBarConfig?.background) {
        setNexusButtonStyle(navBarConfig?.buttonStyle);
      }

      if (!!navBarConfig?.arrowColor) {
        setNexusArrowColor(navBarConfig?.arrowColor);
      }
    }
  }, [nexus?.config_data]);

  const gamesDisplayDesktop = gamesDisplays.find((gamesDisplay) => {
    return gamesDisplay.usage.includes('desktop');
  });

  return (
    <StyledAppBar
      position='static'
      sx={{
        height: { xs: 72, lg: 80 },
        backgroundColor: navBarConfig?.background.color,
      }}
    >
      <Container
        sx={{ height: { xs: 72, lg: 80 } }}
        maxWidth='xl'
        disableGutters
        data-cy='demiplane-nexus-top-nav'
      >
        <Toolbar
          disableGutters
          sx={{
            height: { xs: 72, lg: 80 },
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box display='flex' position='relative' height='100%' flexGrow={1}>
            <Box
              zIndex={20}
              display='flex'
              alignItems='center'
              height='100%'
              pl={2}
              flexGrow={1}
              tabIndex={0}
            >
              {!!nexusBackgroundImage && (
                <Image
                  loader={imgOpLoader}
                  alt='nexus nav background'
                  src={nexusBackgroundImage}
                  fill
                />
              )}
              <NexusDemiplaneMenu gamesDisplay={gamesDisplayDesktop} />
              <NexusMobileMenu config={navBarConfig} topNav={topNav} />
              {!!nexusLogo ? (
                <Link href={`/nexus/${nexus?.slug}`}>
                  <StyledImage
                    loader={imgOpLoader}
                    alt='nexus logo'
                    src={nexusLogo ?? logoImage.src}
                    width={180}
                    height={54}
                    style={{ cursor: 'pointer', margin: 0 }}
                    data-cy='top-nav-nexus-logo'
                    sx={{
                      width: !!nexusLogo
                        ? { xs: 95, sm: 126 }
                        : { xs: 95, sm: 126, lg: 180 },
                      height: !!nexusLogo
                        ? { xs: 41, sm: 54 }
                        : { xs: 30, sm: 39, lg: 54 },
                    }}
                  />
                </Link>
              ) : (
                <Box
                  sx={{
                    width: !!nexusLogo ? 126 : { xs: 126, lg: 180 },
                  }}
                />
              )}

              <NexusNavigationButtons
                buttonStyle={nexusButtonStyle}
                arrowColor={nexusArrowColor}
                topNav={topNav}
                showSearch={showSearch}
              />
            </Box>
          </Box>

          <Box pr={2} zIndex={20}>
            <UserButtons
              currentUser={currentUser}
              isCommerceEnabled={isCommerceEnabled}
              showSearch={showSearch}
              setShowSearch={setShowSearch}
            />
          </Box>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
};

export const NexusTopNavigation = (props: NexusTopNavigationProps) => (
  <ThemeProvider theme={topNavTheme}>
    <NexusTopNavigationBase {...props} />
  </ThemeProvider>
);
