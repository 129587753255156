import React from 'react';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { MenuButton } from '../../Shared/MenuButton';
import { MenuMemberButton } from '../../Shared/MenuMemberButton';
import { NexusLibraryMenu } from '../NexusLibraryMenu';
import { NexusGameRulesMenu } from '../NexusGameRulesMenu';
import { NexusTopNavData } from 'src/lib/pageDataLoading/pageDataLoading';

interface NexusNavigationButtonsProps {
  buttonStyle?: SxProps;
  arrowColor?: string;
  topNav: NexusTopNavData;
  showSearch?: boolean;
}

export const NexusNavigationButtons = ({
  buttonStyle,
  arrowColor,
  topNav,
  showSearch,
}: NexusNavigationButtonsProps) => {
  const { currentUser, isSubscribedSwell, isSubscribedBraintree } = topNav;
  return (
    <Box
      alignItems='center'
      pl={2}
      flexGrow={1}
      className='nexus-nav-menu nav-desktop'
      display={{ xs: 'none', md: !!showSearch ? 'none' : 'flex' }}
    >
      {!topNav.nexus?.is_publisher && (
        <>
          <NexusLibraryMenu
            buttonStyle={buttonStyle}
            arrowColor={arrowColor}
            topNav={topNav}
          />
          <NexusGameRulesMenu
            buttonStyle={buttonStyle}
            arrowColor={arrowColor}
            topNav={topNav}
          />
        </>
      )}
      <MenuButton
        role='menuitem'
        aria-label='characters'
        sx={{ mx: 1.5 }}
        link
        linkUrl={{
          pathname: '/characters',
          query: { nexusId: topNav.nexus?.id },
        }}
        linkAs='/characters'
        buttonStyle={buttonStyle}
        data-cy='top-nav-nexus-characters-btn'
        className='nexus-nav-menu-btn characters-btn nav-desktop'
      >
        Characters
      </MenuButton>
      <MenuButton
        role='menuitem'
        aria-label='groups'
        sx={{ mx: 1.5 }}
        link
        linkUrl='/groups'
        buttonStyle={buttonStyle}
        data-cy='top-nav-nexus-groups-btn'
        className='nexus-nav-menu-btn groups-btn nav-desktop'
      >
        Groups
      </MenuButton>
      {!!currentUser && !isSubscribedSwell && !isSubscribedBraintree && (
        <MenuMemberButton
          role='button'
          aria-label='subscribe now'
          sx={{ mx: 1.5, display: { xs: 'none', lg: 'block' } }}
          link
          linkUrl='/subscription'
          data-cy='header-become-member-btn'
          className='nexus-nav-menu-btn become-member-btn nav-desktop'
        >
          Subscribe Now
        </MenuMemberButton>
      )}
    </Box>
  );
};
