import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useState } from 'react';
import { NexusTopNavData } from 'src/lib/pageDataLoading/pageDataLoading';
import { dynamicImport } from 'src/lib/dynamicImport';

const NexusMobileDropDownMenu = dynamicImport(
  'NexusMobileDropDownMenu',
  () =>
    import('../NexusMobileDropDownMenu').then(
      (mod) => mod.NexusMobileDropDownMenu
    ),
  { ssr: false }
);

interface NexusMobileMenuProps {
  config: any;
  topNav: NexusTopNavData;
}

export const NexusMobileMenu = ({ config, topNav }: NexusMobileMenuProps) => {
  const [mainNavAnchorEl, setMainNavAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const handleMainNavClick = (event: React.MouseEvent<HTMLElement>) => {
    setMainNavAnchorEl(event.currentTarget);
  };

  const handleMainNavClose = () => {
    setMainNavAnchorEl(null);
  };

  return (
    <>
      <IconButton
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
        onClick={handleMainNavClick}
        role='menubutton'
        aria-haspopup='true'
        aria-expanded={!!mainNavAnchorEl}
        aria-label='nexus navigation menu'
      >
        <MenuIcon />
      </IconButton>

      {!!mainNavAnchorEl && (
        <NexusMobileDropDownMenu
          config={config}
          anchorEl={mainNavAnchorEl}
          onClose={handleMainNavClose}
          topNav={topNav}
        />
      )}
    </>
  );
};
