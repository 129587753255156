import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { NexusMenuProductSourceFieldsFragment } from 'Components/NexusNavigation/NexusNavbar/Components/PathfinderNexusMenu/nexus-menu-product-source.fragment.generated';
import {
  IsProductKeyWaveEntitledDocument,
  IsProductKeyWaveEntitledQuery,
} from 'Graphql/is-product-key-wave-entitled.query.generated';
import { useSourcesMenuUserEntitlementsQuery } from 'Graphql/sources-menu-user-entitlements.query.generated';
import { useRxJsEmitter } from 'Hooks/RxJsEmitter';
import React, { useCallback, useEffect, useState } from 'react';
import { NexusTopNavData } from 'src/lib/pageDataLoading';
import { OperationResult, useClient } from 'urql';
import { MenuButton } from '../../Shared/MenuButton';
import { dynamicImport } from 'src/lib/dynamicImport';

const PathfinderNexusMenu = dynamicImport(
  'PathfinderNexusMenu',
  () =>
    import(
      'src/components/NexusNavigation/NexusNavbar/Components/PathfinderNexusMenu'
    ).then((mod) => mod.PathfinderNexusMenu),
  { ssr: false }
);

const MarvelRulesMenu = dynamicImport(
  'MarvelRulesMenu',
  () =>
    import(
      'src/components/NexusNavigation/NexusNavbar/Components/MarvelRulesMenu'
    ).then((mod) => mod.MarvelRulesMenu),
  { ssr: false }
);
const VampireRulesMenu = dynamicImport(
  'VampireRulesMenu',
  () =>
    import(
      'src/components/NexusNavigation/NexusNavbar/Components/VampireRulesMenu'
    ).then((mod) => mod.VampireRulesMenu),
  { ssr: false }
);
const AvatarRulesMenu = dynamicImport(
  'AvatarRulesMenu',
  () =>
    import(
      'src/components/NexusNavigation/NexusNavbar/Components/AvatarRulesMenu'
    ).then((mod) => mod.AvatarRulesMenu),
  { ssr: false }
);
const MutantRulesMenu = dynamicImport(
  'MutantRulesMenu',
  () =>
    import(
      'src/components/NexusNavigation/NexusNavbar/Components/MutantGameRulesMenu'
    ).then((mod) => mod.MutantRulesMenu),
  { ssr: false }
);
const AlienRulesMenu = dynamicImport(
  'AlienRulesMenu',
  () =>
    import(
      'src/components/NexusNavigation/NexusNavbar/Components/AlienRulesMenu'
    ).then((mod) => mod.AlienRulesMenu),
  { ssr: false }
);
const HunterRulesMenu = dynamicImport(
  'HunterRulesMenu',
  () =>
    import(
      'src/components/NexusNavigation/NexusNavbar/Components/HunterRulesMenu'
    ).then((mod) => mod.HunterRulesMenu),
  { ssr: false }
);
const NexusGeneralRuleMenu = dynamicImport(
  'NexusGeneralRuleMenu',
  () =>
    import(
      'src/components/NexusNavigation/NexusNavbar/Components/NexusGeneralRuleMenu'
    ).then((mod) => mod.NexusGeneralRuleMenu),
  { ssr: false }
);

interface IValidProduct {
  sourceName: string;
  sourceId: string;
  sourceSlug?: string;
  nexusAssetSlug: string;
  productId: string;
  state?: string;
  demiplaneSlug: string | null | undefined;
  productBundle: boolean;
  type?: string;
  isEntitled: boolean;
}

interface NexusGameRulesMenuProps {
  buttonStyle?: any;
  arrowColor?: string;
  topNav: NexusTopNavData;
}

export const NexusGameRulesMenu = ({
  buttonStyle,
  arrowColor,
  topNav,
}: NexusGameRulesMenuProps) => {
  const {
    currentUser,
    nexus,
    characterPrimer,
    categoryPrimer,
    products,
    pathfinderProducts,
  } = topNav;
  const { listen } = useRxJsEmitter();
  const client = useClient();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [rulesArrowClicked, setRulesArrowClicked] = useState(false);
  const [gameRulesPopperOpen, setGameRulesPopperOpen] = useState(false);

  const [validRulebooks, setValidRulebooks] = useState<IValidProduct[]>([]);
  const [validLostOmens, setValidLostOmens] = useState<IValidProduct[]>([]);
  const [validStandaloneAdventures, setValidStandaloneAdventures] = useState<
    IValidProduct[]
  >([]);
  const [nexusAssetSlug, setNexusAssetSlug] = useState<string | undefined>();
  const [sourceLoading, setSourceLoading] = useState<boolean>(false);
  const [productSourceIds, setProductSourceIds] = useState<string[]>([]);
  const [ruleBookSourceIds, setRulebookSourceIds] = useState<string[]>([]);
  const [lostOmensSourceIds, setLostOmensSourceIds] = useState<string[]>([]);
  const [standAloneSourceIds, setStandAloneSourceIds] = useState<string[]>([]);
  const [gameRulesElements, setGameRulesElements] = useState<any[]>([]);

  const [pf2eRulebookEntitlementResult, refresPf2eRulebookhEntitlements] =
    useSourcesMenuUserEntitlementsQuery({
      variables: {
        category: 'source',
        ids: ruleBookSourceIds,
      },
      pause: !currentUser || !ruleBookSourceIds.length,
      requestPolicy: 'network-only',
    });
  const {
    data: pf2eRulebookEntitlementData,
    fetching: pf2eRulebookEntitlementDataLoading,
  } = pf2eRulebookEntitlementResult;

  const [pf2eLostOmensEntitlementResult, refreshPf2eLostOmensEntitlements] =
    useSourcesMenuUserEntitlementsQuery({
      variables: {
        category: 'source',
        ids: lostOmensSourceIds,
      },
      pause: !currentUser || !lostOmensSourceIds.length,
      requestPolicy: 'network-only',
    });
  const {
    data: pf2eLostOmensEntitlementData,
    fetching: pf2eLostOmensEntitlementDataLoading,
  } = pf2eLostOmensEntitlementResult;

  const [pf2eStandAloneEntitlementResult, refresPf2eStandAlonehEntitlements] =
    useSourcesMenuUserEntitlementsQuery({
      variables: {
        category: 'source',
        ids: standAloneSourceIds,
      },
      pause: !currentUser || !standAloneSourceIds.length,
      requestPolicy: 'network-only',
    });
  const {
    data: pf2eStandAloneEntitlementData,
    fetching: pf2eStandAloneEntitlementDataLoading,
  } = pf2eStandAloneEntitlementResult;

  const [entitlementResult, refreshEntitlements] =
    useSourcesMenuUserEntitlementsQuery({
      variables: {
        category: 'source',
        ids: productSourceIds,
      },
      pause: !currentUser || !productSourceIds.length,
      requestPolicy: 'network-only',
    });
  const { data: entitlementData, fetching: entitlementDataLoading } =
    entitlementResult;

  const findSource = useCallback(
    async (source: NexusMenuProductSourceFieldsFragment) => {
      // Reusable function to check source entitlement based on state
      let found = false;

      const pathfinderRulebookEntitlement =
        pf2eRulebookEntitlementData?.slsUserEntitlements?.data.find(
          (e) => e.id === source.id
        );

      const pathfinderLostOmensEntitlement =
        pf2eLostOmensEntitlementData?.slsUserEntitlements?.data.find(
          (e) => e.id === source.id
        );

      const pathfinderStandAloneEntitlement =
        pf2eStandAloneEntitlementData?.slsUserEntitlements?.data.find(
          (e) => e.id === source.id
        );

      const pathfinderEntitlement =
        pathfinderRulebookEntitlement ??
        pathfinderLostOmensEntitlement ??
        pathfinderStandAloneEntitlement;

      const entitlement = entitlementData?.slsUserEntitlements?.data.find(
        (e) => e.id === source.id
      );

      if (
        source.source_state.name === 'ACTIVE' ||
        source.source_state.name === 'PREORDER'
      ) {
        found = true;
      } else if (source.source_state.name === 'EARLY_ACCESS') {
        const isWaveEntitledResult: OperationResult<IsProductKeyWaveEntitledQuery> =
          await client
            .query(IsProductKeyWaveEntitledDocument, {
              sourceIds: [source.id],
              earlyAccessEnabled: true,
              preorderAccessEnabled: false,
            })
            .toPromise();

        if (!!isWaveEntitledResult.error) {
          console.log(isWaveEntitledResult.error);
        }

        if (
          !!isWaveEntitledResult.data?.isProductKeyWaveEntitled?.data?.entitled
        ) {
          found = true;
        }
      } else if (source.source_state.name === 'ARCHIVED') {
        // if state is archived, but user owns it, then we'll show
        // otherwise hide it.
        if (entitlement?.owned.userStatus) {
          found = true;
        }
      }
      return {
        found,
        entitlement:
          nexus?.slug === 'pathfinder2e' ? pathfinderEntitlement : entitlement,
      };
    },
    [
      pf2eRulebookEntitlementData?.slsUserEntitlements?.data,
      pf2eLostOmensEntitlementData?.slsUserEntitlements?.data,
      pf2eStandAloneEntitlementData?.slsUserEntitlements?.data,
      entitlementData?.slsUserEntitlements?.data,
      nexus?.slug,
      client,
    ]
  );

  useEffect(() => {
    const setupPathfinderProducts = async () => {
      if (nexus?.slug === 'pathfinder2e' && pathfinderProducts.length >= 1) {
        const sourceProductCategory = pathfinderProducts[0];

        setSourceLoading(true);

        const coreRule = sourceProductCategory.productCategoriesByParentId.find(
          (pc) => pc.name === 'rulebooks'
        );
        const lostOmens =
          sourceProductCategory.productCategoriesByParentId.find(
            (pc) => pc.name === 'lost omens'
          );
        const standaloneAdventures =
          sourceProductCategory.productCategoriesByParentId.find(
            (pc) => pc.name === 'standalone adventures'
          );

        if (!!coreRule) {
          const _validRulebooks: IValidProduct[] = [];

          for (const ppc of coreRule.product_product_categories) {
            if (!ppc.product.bundle) {
              const source = ppc.product.source;

              if (!!source) {
                const result = await findSource(source);
                if (
                  result.found &&
                  !_validRulebooks.find((vp) => vp.sourceId === source.id)
                ) {
                  _validRulebooks.push({
                    sourceName: ppc.product.name,
                    sourceId: source.id ?? undefined,
                    sourceSlug: ppc.product.demiplane_slug ?? undefined,
                    nexusAssetSlug: nexus?.asset_slug,
                    productId: ppc.product.id,
                    state: source.source_state.name,
                    demiplaneSlug: ppc.product.demiplane_slug,
                    productBundle: ppc.product.bundle,
                    isEntitled: result.entitlement?.owned.userStatus ?? false,
                  });
                }
              }
            }
          }
          setValidRulebooks(_validRulebooks);
          setNexusAssetSlug(nexus?.asset_slug);
        }

        if (!!lostOmens) {
          let _validLostOmens: IValidProduct[] = [];

          for (const ppc of lostOmens.product_product_categories) {
            if (!ppc.product.bundle) {
              const source = ppc.product.source;

              if (!!source) {
                const result = await findSource(source);

                if (
                  result.found &&
                  !_validLostOmens.find((vp) => vp.sourceId === source.id)
                ) {
                  _validLostOmens.push({
                    sourceName: ppc.product.name,
                    sourceId: source.id ?? undefined,
                    sourceSlug: ppc.product.demiplane_slug ?? undefined,
                    nexusAssetSlug: nexus?.asset_slug,
                    productId: ppc.product.id,
                    state: source.source_state.name,
                    demiplaneSlug: ppc.product.demiplane_slug,
                    productBundle: ppc.product.bundle,
                    isEntitled: !!result.entitlement?.owned.userStatus,
                  });
                }
              }
            }
          }

          setValidLostOmens(_validLostOmens);
        }

        if (!!standaloneAdventures) {
          const _validStandaloneAdventures: IValidProduct[] = [];

          for (const ppc of standaloneAdventures.product_product_categories) {
            if (!ppc.product.bundle) {
              const source = ppc.product.source;

              if (!!source) {
                const result = await findSource(source);
                if (
                  result.found &&
                  !_validStandaloneAdventures.find(
                    (vp) => vp.sourceId === source.id
                  )
                ) {
                  _validStandaloneAdventures.push({
                    sourceName: ppc.product.name,
                    sourceId: source.id ?? undefined,
                    sourceSlug: ppc.product.demiplane_slug ?? undefined,
                    nexusAssetSlug: nexus?.asset_slug,
                    productId: ppc.product.id,
                    state: source.source_state.name,
                    demiplaneSlug: ppc.product.demiplane_slug,
                    productBundle: ppc.product.bundle,
                    isEntitled: !!result.entitlement?.owned.userStatus,
                  });
                }
              }
            }
          }

          setValidStandaloneAdventures(_validStandaloneAdventures);
        }

        setSourceLoading(false);
      }
    };
    setupPathfinderProducts();
  }, [
    client,
    pf2eLostOmensEntitlementDataLoading,
    pf2eLostOmensEntitlementData,
    pf2eRulebookEntitlementDataLoading,
    pf2eRulebookEntitlementData,
    pf2eStandAloneEntitlementDataLoading,
    pf2eStandAloneEntitlementData,
    findSource,
    nexus?.slug,
    nexus?.asset_slug,
    pathfinderProducts,
  ]);

  useEffect(() => {
    if (nexus?.slug === 'pathfinder2e' && !!currentUser) {
      const sourceProductCategory = pathfinderProducts[0];

      const coreRule = sourceProductCategory.productCategoriesByParentId.find(
        (pc) => pc.name === 'rulebooks'
      );
      const lostOmens = sourceProductCategory.productCategoriesByParentId.find(
        (pc) => pc.name === 'lost omens'
      );
      const standaloneAdventures =
        sourceProductCategory.productCategoriesByParentId.find(
          (pc) => pc.name === 'standalone adventures'
        );

      const ruleBookSourceIds = coreRule?.product_product_categories
        .filter((prodCat) => !!prodCat.product.source)
        .map((prodCat) => {
          return prodCat.product.source!.id;
        });

      const lostOmensSourceIds = lostOmens?.product_product_categories
        .filter((prodCat) => !!prodCat.product.source)
        .map((prodCat) => {
          return prodCat.product.source!.id;
        });

      const standAloneSourceIds =
        standaloneAdventures?.product_product_categories
          .filter((prodCat) => !!prodCat.product.source)
          .map((prodCat) => {
            return prodCat.product.source!.id;
          });

      setRulebookSourceIds(ruleBookSourceIds ?? []);
      setLostOmensSourceIds(lostOmensSourceIds ?? []);
      setStandAloneSourceIds(standAloneSourceIds ?? []);
    }

    if (nexus?.slug !== 'pathfinder2e' && !!currentUser) {
      const productSourceIds: string[] = [];

      const sourceProductCategory = products[0];

      const allCategories = sourceProductCategory
        ?.productCategoriesByParentId[0]
        ? sourceProductCategory.productCategoriesByParentId[0]
            .productCategoriesByParentId
        : [];

      for (const cat of allCategories) {
        for (const ppc of cat.product_product_categories) {
          if (!!ppc.product.source?.id) {
            productSourceIds.push(ppc.product.source?.id);
          }
        }
      }

      setProductSourceIds(productSourceIds);
    }
  }, [currentUser, nexus?.slug, pathfinderProducts, products]);

  useEffect(() => {
    const setupProducts = async () => {
      if (nexus?.slug !== 'pathfinder2e' && products.length > 0 && !!nexus) {
        const sourceProductCategory = products[0];

        setSourceLoading(true);

        const allCategories =
          sourceProductCategory.productCategoriesByParentId[0]
            .productCategoriesByParentId;
        const _validProducts: IValidProduct[] = [];

        for (const cat of allCategories) {
          for (const ppc of cat.product_product_categories) {
            if (!ppc.product.bundle) {
              const source = ppc.product.source;

              if (!!source) {
                const result = await findSource(source);
                if (
                  result.found &&
                  !_validProducts.find((vp) => vp.sourceId === source.id)
                ) {
                  _validProducts.push({
                    sourceName: ppc.product.name,
                    sourceId: source.id ?? undefined,
                    sourceSlug: ppc.product.demiplane_slug ?? undefined,
                    nexusAssetSlug: nexus.asset_slug,
                    productId: ppc.product.id,
                    state: source.source_state.name,
                    demiplaneSlug: ppc.product.demiplane_slug,
                    productBundle: ppc.product.bundle,
                    isEntitled: !!result.entitlement?.owned.userStatus,
                  });
                }
              }
            }
          }
        }
        setNexusAssetSlug(nexus.asset_slug);
        setSourceLoading(false);
      }
    };
    setupProducts();
  }, [
    client,
    entitlementData,
    entitlementDataLoading,
    findSource,
    nexus,
    products,
  ]);

  useEffect(() => {
    const sub = listen('reloadEntitlements', () => {
      if (!!currentUser) {
        refresPf2eRulebookhEntitlements({
          variables: {
            category: 'source',
            ids: ruleBookSourceIds,
          },
          pause: !currentUser || !ruleBookSourceIds?.length,
          requestPolicy: 'network-only',
        });

        refreshPf2eLostOmensEntitlements({
          variables: {
            category: 'source',
            ids: lostOmensSourceIds,
          },
          pause: !currentUser || !lostOmensSourceIds?.length,
          requestPolicy: 'network-only',
        });
        refresPf2eStandAlonehEntitlements({
          variables: {
            category: 'source',
            ids: standAloneSourceIds,
          },
          pause: !currentUser || !standAloneSourceIds?.length,
          requestPolicy: 'network-only',
        });

        refreshEntitlements({
          variables: {
            category: 'source',
            ids: productSourceIds,
          },
          pause: !currentUser || !productSourceIds.length,
          requestPolicy: 'network-only',
        });
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, [
    currentUser,
    listen,
    lostOmensSourceIds,
    productSourceIds,
    refresPf2eRulebookhEntitlements,
    refresPf2eStandAlonehEntitlements,
    refreshEntitlements,
    refreshPf2eLostOmensEntitlements,
    ruleBookSourceIds,
    standAloneSourceIds,
  ]);

  useEffect(() => {
    if (
      !!nexus &&
      !!nexus.library_primer_data &&
      nexus.library_primer_data.length > 0 &&
      !!nexus.listing_data &&
      nexus.listing_data.length > 0
    ) {
      setGameRulesElements(
        nexus.library_primer_data.concat(nexus.listing_data)
      );
    }
  }, [nexus]);

  const handleGameRulesClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setRulesArrowClicked(!rulesArrowClicked);
    setGameRulesPopperOpen(true);
  };

  const handleGameRulesClose = () => {
    setAnchorEl(null);
    setGameRulesPopperOpen(false);
    setRulesArrowClicked(false);
  };

  const constructMenuElements = () => {
    switch (nexus?.slug) {
      case 'pathfinder2e': {
        return (
          <>
            {anchorEl && (
              <PathfinderNexusMenu
                menuName='rules'
                anchorEl={anchorEl}
                handleClose={handleGameRulesClose}
                open={gameRulesPopperOpen}
                onClickAway={() => handleGameRulesClose()}
                rulebooks={validRulebooks}
                lostOmens={validLostOmens}
                standaloneAdventures={validStandaloneAdventures}
                productsLoading={sourceLoading}
                nexusAssetSlug={nexusAssetSlug ?? ''}
                gameRulesElements={gameRulesElements}
                classPrimer={topNav.classPrimer}
              />
            )}
          </>
        );
      }

      case 'vampire': {
        return (
          <>
            {anchorEl && (
              <VampireRulesMenu
                anchorEl={anchorEl}
                handleClose={handleGameRulesClose}
                open={gameRulesPopperOpen}
                onClickAway={() => handleGameRulesClose()}
              />
            )}
          </>
        );
      }

      case 'hunter': {
        return (
          <>
            {anchorEl && (
              <HunterRulesMenu
                anchorEl={anchorEl}
                handleClose={handleGameRulesClose}
                open={gameRulesPopperOpen}
                onClickAway={() => handleGameRulesClose()}
                gameRulesElements={gameRulesElements}
              />
            )}
          </>
        );
      }

      case 'mutantyearzero': {
        return (
          <>
            {anchorEl && (
              <MutantRulesMenu
                anchorEl={anchorEl}
                handleClose={handleGameRulesClose}
                open={gameRulesPopperOpen}
                onClickAway={() => handleGameRulesClose()}
              />
            )}
          </>
        );
      }

      case 'alienrpg': {
        return (
          <>
            {anchorEl && (
              <AlienRulesMenu
                anchorEl={anchorEl}
                handleClose={handleGameRulesClose}
                open={gameRulesPopperOpen}
                onClickAway={() => handleGameRulesClose()}
              />
            )}
          </>
        );
      }

      case 'marvelrpg': {
        return (
          <>
            {anchorEl && (
              <MarvelRulesMenu
                anchorEl={anchorEl}
                handleClose={handleGameRulesClose}
                open={gameRulesPopperOpen}
                onClickAway={() => handleGameRulesClose()}
                gameRulesElements={gameRulesElements}
                characterPrimer={characterPrimer}
              />
            )}
          </>
        );
      }

      case 'avatarlegends': {
        return (
          <>
            {anchorEl && (
              <AvatarRulesMenu
                anchorEl={anchorEl}
                handleClose={handleGameRulesClose}
                open={gameRulesPopperOpen}
                onClickAway={() => handleGameRulesClose()}
              />
            )}
          </>
        );
      }

      // candela uses general rule menu with some specific props
      case 'candelaobscura': {
        return (
          <>
            {anchorEl && (
              <NexusGeneralRuleMenu
                anchorEl={anchorEl}
                handleClose={handleGameRulesClose}
                open={gameRulesPopperOpen}
                onClickAway={() => handleGameRulesClose()}
                gameRulesElements={gameRulesElements}
                categoryPrimer={categoryPrimer}
                showViewAll={false}
                topRow={5}
                defaultPrimerCount={categoryPrimer?.length}
                nexusAssetSlug={nexusAssetSlug ?? ''}
                sorting={gameRulesElements[0]?.sorting}
              />
            )}
          </>
        );
      }

      // daggerheart uses general rule menu with some specific props
      case 'daggerheart': {
        return (
          <>
            {anchorEl && (
              <NexusGeneralRuleMenu
                anchorEl={anchorEl}
                handleClose={handleGameRulesClose}
                open={gameRulesPopperOpen}
                onClickAway={() => handleGameRulesClose()}
                gameRulesElements={gameRulesElements}
                categoryPrimer={categoryPrimer}
                showViewAll={false}
                topRow={5}
                defaultPrimerCount={categoryPrimer?.length}
                nexusAssetSlug={nexusAssetSlug ?? ''}
                sorting={gameRulesElements[0]?.sorting}
              />
            )}
          </>
        );
      }

      // general rule menu default props and usage
      default: {
        return (
          <>
            {anchorEl && (
              <NexusGeneralRuleMenu
                anchorEl={anchorEl}
                handleClose={handleGameRulesClose}
                open={gameRulesPopperOpen}
                onClickAway={() => handleGameRulesClose()}
                gameRulesElements={gameRulesElements}
                categoryPrimer={categoryPrimer}
                showViewAll={
                  nexus?.config_data?.game_rules_menu?.showViewAll ?? true
                }
                defaultPrimerCount={
                  nexus?.config_data?.game_rules_menu?.length ?? 5
                }
                topRow={nexus?.config_data?.game_rules_menu?.topRow ?? 5}
                nexusAssetSlug={nexusAssetSlug ?? ''}
                sorting={gameRulesElements[0]?.sorting ?? undefined}
              />
            )}
          </>
        );
      }
    }
  };

  return (
    <>
      <MenuButton
        role='menubutton'
        aria-haspopup='true'
        aria-expanded={!!anchorEl}
        aria-label='game rules menu button'
        sx={{ mx: 1.5 }}
        endIcon={
          !!anchorEl ? (
            <ArrowDropUpIcon sx={{ color: arrowColor ?? 'demi.blue' }} />
          ) : (
            <ArrowDropDownIcon sx={{ color: arrowColor ?? 'demi.blue' }} />
          )
        }
        onClick={handleGameRulesClick}
        buttonStyle={buttonStyle}
        data-cy='top-nav-nexus-game-rules-btn'
        className={
          !!anchorEl
            ? 'top-nav-nexus-game-rules-btn-active'
            : 'top-nav-nexus-game-rules-btn'
        }
      >
        Game Rules
      </MenuButton>
      {constructMenuElements()}
    </>
  );
};
