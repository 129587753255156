import * as Types from './types';

import { gql } from 'urql';
import { NexusByNexusSlugFieldsFragmentDoc } from './nexus-by-slug.fragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type NexusBySlugQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
}>;


export type NexusBySlugQuery = { __typename?: 'query_root', demiplane_nexus: Array<{ __typename?: 'demiplane_nexus', id: string, name: string, active: boolean, asset_slug: string, slug: string, footer_data?: any | null, listing_data?: any | null, library_primer_data?: any | null, call_to_action_data?: any | null, config_data?: any | null, is_publisher: boolean, game_type: { __typename?: 'demiplane_game_type', id: string }, element_category_route_mappings: Array<{ __typename?: 'demiplane_element_category_route_mapping', id: number, display_name?: string | null, element_category: string }> }> };


export const NexusBySlugDocument = gql`
    query nexusBySlug($slug: String!) {
  demiplane_nexus(where: {slug: {_eq: $slug}}) {
    ...NexusByNexusSlugFields
  }
}
    ${NexusByNexusSlugFieldsFragmentDoc}`;

export function useNexusBySlugQuery(options: Omit<Urql.UseQueryArgs<NexusBySlugQueryVariables>, 'query'>) {
  return Urql.useQuery<NexusBySlugQuery, NexusBySlugQueryVariables>({ query: NexusBySlugDocument, ...options });
};