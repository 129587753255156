import React from 'react';

interface TwitchIconProps {
  height: string;
  width: string;
}

export const TwitchIcon = ({ height, width }: TwitchIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.43307 0L0.358398 2.746V13.97H4.17906V16H6.3284L8.3584 13.97H11.4631L15.6424 9.79067V0H1.43307V0ZM14.2091 9.07467L11.8211 11.4627H8.0004L5.9704 13.4927V11.4627H2.7464V1.43267H14.2091V9.07467ZM11.8211 4.17933V8.354H10.3884V4.17933H11.8211ZM8.0004 4.17933V8.354H6.56773V4.17933H8.0004Z'
        fill='#FDFDFD'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
