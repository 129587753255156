import * as Types from './types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SourcesMenuUserEntitlementsQueryVariables = Types.Exact<{
  category?: Types.InputMaybe<Types.Scalars['String']['input']>;
  ids?: Types.InputMaybe<Array<Types.Scalars['bigint']['input']> | Types.Scalars['bigint']['input']>;
}>;


export type SourcesMenuUserEntitlementsQuery = { __typename?: 'query_root', slsUserEntitlements?: { __typename?: 'UserEntitlementsOutput', error?: string | null, message?: string | null, success: boolean, data: Array<{ __typename?: 'UserEntitlementsOutputData', id: string, category: string, earlyAccess: { __typename?: 'UserEntitlementsDataPermission', sharedByUserIds: Array<string>, userStatus: boolean, hasPurchased: boolean }, owned: { __typename?: 'UserEntitlementsDataPermission', sharedByUserIds: Array<string>, userStatus: boolean, hasPurchased: boolean }, preorder: { __typename?: 'UserEntitlementsDataPermission', sharedByUserIds: Array<string>, userStatus: boolean, hasPurchased: boolean } }> } | null };


export const SourcesMenuUserEntitlementsDocument = gql`
    query sourcesMenuUserEntitlements($category: String, $ids: [bigint!]) {
  slsUserEntitlements(category: $category, ids: $ids) {
    data {
      id
      category
      earlyAccess {
        sharedByUserIds
        userStatus
        hasPurchased
      }
      id
      owned {
        sharedByUserIds
        userStatus
        hasPurchased
      }
      preorder {
        sharedByUserIds
        userStatus
        hasPurchased
      }
    }
    error
    message
    success
  }
}
    `;

export function useSourcesMenuUserEntitlementsQuery(options?: Omit<Urql.UseQueryArgs<SourcesMenuUserEntitlementsQueryVariables>, 'query'>) {
  return Urql.useQuery<SourcesMenuUserEntitlementsQuery, SourcesMenuUserEntitlementsQueryVariables>({ query: SourcesMenuUserEntitlementsDocument, ...options });
};