export const DemiplaneSmallLogo = () => {
  return (
    <svg
      width='36'
      height='38'
      viewBox='0 0 36 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_4691_10845)'>
        <path
          d='M4.41945 30.4647L1.11586 22.7102L2.3166 15.006L7.00009 8.76661L6.6449 6.9938L13.865 4.09255L20.8022 4.52947L23.9204 3.7405L24.9702 1.95512L22.6442 0L12.922 0.678949L5.05125 5.85908L0.581507 13.1169L0 22.1853L4.41945 30.4647Z'
          fill='#00ABFF'
        />
        <path
          d='M28.9315 29.2043L27.6302 29.0125L20.1335 34.419L11.6498 31.9421L9.13515 33.1774L7.39062 32.6807L9.78266 34.9502L20.6458 37.0782L30.4434 31.7252L35.5827 23.4143L35.9976 14.0788L30.8898 7.00012L35.5356 14.1008L34.5454 22.7951L28.9315 29.2043Z'
          fill='#00ABFF'
        />
        <path
          d='M18.3103 11.3182C20.2756 11.3182 21.9616 12.019 23.3581 13.4156C24.7495 14.807 25.4452 16.4879 25.4452 18.4532C25.4452 20.4185 24.7495 22.1044 23.3581 23.501C21.9616 24.8924 20.2756 25.5881 18.3103 25.5881H13.552V13.6999H12.125L13.1203 11.3182H18.3103ZM18.3103 23.2166C19.6205 23.2166 20.7428 22.7494 21.6721 21.8201C22.6014 20.8907 23.0686 19.7684 23.0686 18.4583C23.0686 17.1481 22.6014 16.0258 21.6721 15.0965C20.7428 14.1671 19.6205 13.6999 18.3103 13.6999H15.9286V23.2166H18.3103Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_4691_10845'>
          <rect width='36' height='37.0781' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
