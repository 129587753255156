import React, { useState, useEffect, ReactElement } from 'react';
import { useStyles } from './styles';
import { useMediaQuery, Typography, IconButton, Box } from '@mui/material';
import { Facebook, Instagram, Twitter, YouTube } from '@mui/icons-material';
import { DiscordIcon } from 'Icons/DiscordIcon';
import { TwitchIcon } from 'Icons/TwitchIcon';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useNexusBySlugQuery } from 'Graphql/nexus-by-slug.query.generated';
import { ThemeProvider } from '@mui/material/styles';
import { NexusTheme } from '../../../theme/nexusTheme';
import { imgOpLoader } from '@demiplane-dev/ui-components/src/image-optimization-loader';
const demiplaneImage = require('../../../../public/logo/demiplane.png').default;

const NexusFooterComp = () => {
  const minWidth = useMediaQuery('(max-width:1024px)');
  const cdn = `${process.env.NEXT_PUBLIC_CONTENT_ENDPOINT_TRANSFORMED}`;
  const [gradientColor, setGradientColor] = useState('');
  const [gradOpacity, setGradOpacity] = useState(0);
  const [colorConfig, setColorConfig] = useState<any>();
  const router = useRouter();
  const { nexusSlug } = router.query;
  const [logo, setLogo] = useState('');
  const [background, setBackground] = useState('');
  const [nexus, setNexus] = useState<any>();
  const [nexusConfig, setNexusConfig] = useState<any>();
  const [fallback, setFallback] = useState(false);
  const [configTheme, setConfigTheme] = useState<any>();
  const [config, setConfig] = useState<any>();

  const [nexusResult] = useNexusBySlugQuery({
    variables: {
      slug: nexusSlug as string,
    },
  });
  const { data: nexusData, fetching: nexusLoading } = nexusResult;
  useEffect(() => {
    if (!!nexusData && !!nexusData.demiplane_nexus) {
      setConfig(nexusData.demiplane_nexus[0].footer_data);
    }
  }, [nexusData]);

  useEffect(() => {
    if (!!nexusData && !nexusLoading && !!nexusData.demiplane_nexus) {
      setColorConfig(nexusData.demiplane_nexus[0].config_data?.navBar);
      setNexus(nexusData.demiplane_nexus[0]);
      setNexusConfig(nexusData.demiplane_nexus[0].config_data);

      if (
        !!nexusData.demiplane_nexus[0].config_data &&
        !!nexusData.demiplane_nexus[0].config_data.theme
      ) {
        setConfigTheme(nexusData.demiplane_nexus[0].config_data.theme.palette);
      }
    }
  }, [nexusData, nexusLoading]);

  useEffect(() => {
    const reducer = (config: any) => {
      let _default = false;
      if (!!nexus && !nexus.is_publisher && !nexusConfig?.hideFooter) {
        if (!config?.logo && !config?.background && !config?.gradientColor) {
          _default = true;
        }
      }
      setFallback(_default);

      if (!_default) {
        if (config) {
          if (!!config.logo) {
            setLogo(`${cdn}${config?.logo}`);
          }
          if (!!config?.background) {
            setBackground(`${cdn}${config?.background}`);
          } else {
            setBackground('');
          }
          if (!!config?.gradientColor) {
            setGradientColor(config.gradientColor);
          } else {
            setGradientColor('');
          }

          if (!!config?.gradientOpacity) {
            setGradOpacity(config.gradientOpacity);
          } else {
            setGradOpacity(0);
          }
        } else {
          if (!!configTheme) {
            setGradientColor(configTheme.base.base0);
          } else {
            setGradientColor('');
          }
        }
      } else {
        setLogo(`${cdn}/nexus/defaults/nexus-footer-logo-default.png`);
        setBackground(`${cdn}/nexus/defaults/nexus-footer-bg-default.png`);
      }
    };

    reducer(config);
  }, [
    config,
    cdn,
    nexus,
    nexus?.isPublisher,
    nexusConfig?.theme,
    configTheme,
    nexusConfig?.hideFooter,
  ]);

  const classes = useStyles({
    backgroundColor: gradientColor,
    opacity: gradOpacity,
  });

  if (!config || nexusConfig?.hideFooter) {
    return null;
  }

  let visibleLinkElements: ReactElement[] = [];
  let invisibleLinkElements: ReactElement[] = [];

  if (!!config && !!config.links && config.links.length > 0) {
    visibleLinkElements = config.links.map((l: any) => {
      const vItems = l.items.map((i: any) => {
        return (
          <Link
            href={i.url}
            key={i.url}
            prefetch={false}
            style={{ textDecoration: 'none' }}
          >
            <Typography key={i.url} className={classes.linkText}>
              {i.title}
            </Typography>
          </Link>
        );
      });

      return (
        <Box key={l.title} className={classes.linksColumn}>
          <Box className={classes.headerContainer}>
            <Typography
              style={{ color: colorConfig?.linkColor ?? 'common.white' }}
              className={classes.headerText}
            >
              {l.title}
            </Typography>
          </Box>
          {vItems}
        </Box>
      );
    });

    invisibleLinkElements = config.links.map((l: any) => {
      const iItems = l.items.map((i: any) => {
        return (
          <Typography key={i.url} className={classes.linkText}>
            {i.title}
          </Typography>
        );
      });

      return (
        <Box key={l.title} className={classes.linksColumn}>
          <Box className={classes.headerContainer}>
            <Typography className={classes.headerText}>{l.title}</Typography>
          </Box>
          {iItems}
        </Box>
      );
    });
  }

  return (
    <>
      <ThemeProvider theme={NexusTheme}>
        {!minWidth ? (
          <Box className={`${classes.content} footer-desktop-outer-container`}>
            {!!background && (
              <Image
                loader={imgOpLoader}
                alt='footer background'
                src={background}
                className={`${classes.backgroundImage} footer-desktop-background-image`}
                quality='100'
                style={{ objectFit: 'cover' }}
                fill
                sizes='100dvw'
              />
            )}

            <Box
              className={`${classes.maxWidthContainer} footer-desktop-inner-container`}
            >
              <Box className={classes.nexusLogoContainer}>
                {!!logo && (
                  <>
                    {!fallback ? (
                      <Box className={classes.nexusLogo}>
                        <Image
                          loader={imgOpLoader}
                          alt='nexus logo'
                          src={logo}
                          height={115}
                          width={215}
                          style={{ maxWidth: 'unset' }}
                        />
                      </Box>
                    ) : (
                      <Box className={classes.nexusLogo}>
                        <Image
                          loader={imgOpLoader}
                          alt='nexus logo'
                          src={logo}
                          fill
                          style={{
                            objectFit: 'scale-down',
                            objectPosition: 'left',
                          }}
                          sizes='215px'
                        />
                      </Box>
                    )}
                  </>
                )}
              </Box>
              <Box style={{ display: 'flex' }}>
                <Box style={{ visibility: 'hidden', display: 'flex' }}>
                  <Box style={{ visibility: 'hidden', display: 'flex' }}>
                    {invisibleLinkElements}
                  </Box>
                </Box>
                <Box className={classes.rightLinksContainer}>
                  {visibleLinkElements}
                </Box>
              </Box>
              <Box className={classes.socialContainer}>
                <Box className={classes.socialHeader}>
                  <Typography
                    style={{ color: colorConfig?.linkColor ?? 'common.white' }}
                    className={classes.headerText}
                  >
                    Follow Us
                  </Typography>
                </Box>
                <Box className={classes.socials}>
                  <Link
                    href={'https://twitter.com/DemiplaneRPG'}
                    prefetch={false}
                    target={'_blank'}
                    style={{ textDecoration: 'none' }}
                    rel='noreferrer'
                    aria-label='Twitter'
                  >
                    <IconButton
                      style={{ padding: 0 }}
                      size='large'
                      aria-label='Twitter'
                    >
                      <Twitter classes={{ root: classes.socialIcon }} />
                    </IconButton>
                  </Link>
                  <Link
                    href={'https://www.twitch.tv/demiplanerpg'}
                    prefetch={false}
                    target={'_blank'}
                    style={{ textDecoration: 'none' }}
                    rel='noreferrer'
                    aria-label='Twitch'
                  >
                    <IconButton
                      style={{ padding: 0, marginRight: '1.5rem' }}
                      size='large'
                      aria-label='Twitch'
                    >
                      <TwitchIcon width='1.5rem' height='1.5rem' />
                    </IconButton>
                  </Link>
                  <Link
                    href={'https://www.youtube.com/c/demiplane'}
                    prefetch={false}
                    target={'_blank'}
                    style={{ textDecoration: 'none' }}
                    rel='noreferrer'
                    aria-label='Youtube'
                  >
                    <IconButton
                      style={{ padding: 0 }}
                      size='large'
                      aria-label='Youtube'
                    >
                      <YouTube classes={{ root: classes.socialIcon }} />
                    </IconButton>
                  </Link>
                  <Link
                    href={'https://instagram.com/demiplanerpg'}
                    prefetch={false}
                    target={'_blank'}
                    style={{ textDecoration: 'none' }}
                    rel='noreferrer'
                    aria-label='Instagram'
                  >
                    <IconButton
                      style={{ padding: 0 }}
                      size='large'
                      aria-label='Instagram'
                    >
                      <Instagram classes={{ root: classes.socialIcon }} />
                    </IconButton>
                  </Link>
                  <Link
                    href={'https:/facebook.com/DemiplaneRPG'}
                    prefetch={false}
                    target={'_blank'}
                    style={{ textDecoration: 'none' }}
                    rel='noreferrer'
                    aria-label='Facebook'
                  >
                    <IconButton
                      style={{ padding: 0 }}
                      size='large'
                      aria-label='Facebook'
                    >
                      <Facebook classes={{ root: classes.socialIcon }} />
                    </IconButton>
                  </Link>
                  <Link
                    href={'https://discord.gg/4ra542pWZb'}
                    prefetch={false}
                    target={'_blank'}
                    style={{ textDecoration: 'none' }}
                    rel='noreferrer'
                    aria-label='Discord'
                  >
                    <IconButton
                      style={{ padding: 0 }}
                      size='large'
                      aria-label='Discord'
                    >
                      <DiscordIcon width='1.5rem' height='1.5rem' />
                    </IconButton>
                  </Link>
                </Box>
              </Box>
            </Box>
            <Box
              className={classes.bottomBar}
              sx={(theme) => ({
                backgroundColor: theme.palette.base.base0,
              })}
            >
              <Box className={classes.maxWidthContainer}>
                <Box style={{ display: 'flex' }}>
                  <Link
                    href={`${process.env.NEXT_PUBLIC_CLIENT_ENDPOINT}/`}
                    prefetch={false}
                    className={classes.bottomBarLogoContainer}
                  >
                    <Image
                      loader={imgOpLoader}
                      alt='bottom barr demiplane logo'
                      src={demiplaneImage.src}
                      className={classes.bottomBarLogo}
                      height={60}
                      width={200}
                    />
                  </Link>
                  <Box className={classes.tradeMarkContainer}>
                    <Typography className={classes.tradeMarkText}>
                      ©2024 Demiplane
                    </Typography>
                  </Box>
                  <Box className={classes.supportContainer}>
                    <Link
                      href={'https://www.demiplane.com/privacy-policy'}
                      prefetch={false}
                      target={'_blank'}
                      className={classes.supportText}
                      rel='noreferrer'
                    >
                      Privacy Policy
                    </Link>
                    <Link
                      href={'https://www.demiplane.com/terms-of-service'}
                      prefetch={false}
                      target={'_blank'}
                      className={classes.supportText}
                      rel='noreferrer'
                    >
                      Terms of Service
                    </Link>
                    <Link
                      href={'https://www.demiplane.com/code-of-conduct'}
                      prefetch={false}
                      target={'bz'}
                      className={classes.supportText}
                    >
                      Code of Conduct
                    </Link>
                  </Box>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBottom: '2rem',
                  }}
                >
                  <Box className={classes.legal}>{config.legal}</Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className={`${classes.content} footer-mobile-container`}>
            {!!background && (
              <Image
                loader={imgOpLoader}
                alt='footer background image'
                src={background}
                className={`${classes.backgroundImage} footer-mobile-background-image`}
                quality='100'
                fill
                style={{ objectFit: 'cover' }}
                sizes='100dvw'
              />
            )}
            <Box className={classes.nexusLogoContainerSmall}>
              {!!logo && (
                <>
                  {!fallback ? (
                    <Box className={classes.nexusLogo}>
                      <Image
                        loader={imgOpLoader}
                        alt='nexus logo'
                        src={logo}
                        height={115}
                        width={215}
                      />
                    </Box>
                  ) : (
                    <Box className={classes.nexusLogo}>
                      <Image
                        loader={imgOpLoader}
                        alt='nexus logo'
                        src={logo}
                        height={40}
                        width={215}
                      />
                    </Box>
                  )}
                </>
              )}
            </Box>
            <Box className={classes.linksColumnSmall}>
              {visibleLinkElements.length > 0 && visibleLinkElements[0]}
            </Box>
            <Box className={classes.socialContainerSmall}>
              <Box className={classes.socialHeader}>
                <Typography
                  style={{ color: colorConfig?.linkColor ?? 'common.white' }}
                  className={classes.headerText}
                >
                  Follow Us
                </Typography>
              </Box>
              <Box className={classes.socialsSmall}>
                <Box style={{ display: 'flex' }}>
                  <Link
                    href={'https://twitter.com/DemiplaneRPG'}
                    prefetch={false}
                    target={'_blank'}
                    style={{ textDecoration: 'none' }}
                    rel='noreferrer'
                  >
                    <IconButton style={{ padding: 0 }} size='large'>
                      <Twitter classes={{ root: classes.socialIcon }} />
                    </IconButton>
                  </Link>
                  <Link
                    href={'https://www.twitch.tv/demiplanerpg'}
                    prefetch={false}
                    target={'_blank'}
                    style={{ textDecoration: 'none' }}
                    rel='noreferrer'
                  >
                    <IconButton
                      style={{ padding: 0, marginRight: '1.5rem' }}
                      size='large'
                    >
                      <TwitchIcon width='1.5rem' height='1.5rem' />
                    </IconButton>
                  </Link>
                  <Link
                    href={'https://www.youtube.com/c/demiplane'}
                    prefetch={false}
                    target={'_blank'}
                    style={{ textDecoration: 'none' }}
                    rel='noreferrer'
                  >
                    <IconButton style={{ padding: 0 }} size='large'>
                      <YouTube classes={{ root: classes.socialIcon }} />
                    </IconButton>
                  </Link>
                  <Link
                    href={'https://instagram.com/demiplanerpg'}
                    prefetch={false}
                    target={'_blank'}
                    style={{ textDecoration: 'none' }}
                    rel='noreferrer'
                  >
                    <IconButton style={{ padding: 0 }} size='large'>
                      <Instagram classes={{ root: classes.socialIcon }} />
                    </IconButton>
                  </Link>
                  <Link
                    href={'https:/facebook.com/DemiplaneRPG'}
                    prefetch={false}
                    target={'_blank'}
                    style={{ textDecoration: 'none' }}
                    rel='noreferrer'
                  >
                    <IconButton style={{ padding: 0 }} size='large'>
                      <Facebook classes={{ root: classes.socialIcon }} />
                    </IconButton>
                  </Link>
                  <Link
                    href={'https://discord.gg/4ra542pWZb'}
                    prefetch={false}
                    target={'_blank'}
                    style={{ textDecoration: 'none' }}
                    rel='noreferrer'
                  >
                    <IconButton style={{ padding: 0 }} size='large'>
                      <DiscordIcon width='1.5rem' height='1.5rem' />
                    </IconButton>
                  </Link>
                </Box>
              </Box>
            </Box>
            <Box
              sx={(theme) => ({
                backgroundColor: theme.palette.base.base0,
              })}
              className={classes.bottomBarSmall}
            >
              <Box className={classes.bottomBarSmallLineOne}>
                <Link
                  href={`${process.env.NEXT_PUBLIC_CLIENT_ENDPOINT}`}
                  prefetch={false}
                  className={classes.bottomBarLogoContainer}
                >
                  <Image
                    loader={imgOpLoader}
                    alt='bottom bar demiplane logo'
                    src={demiplaneImage.src}
                    className={classes.bottomBarLogo}
                    height={60}
                    width={200}
                  />
                </Link>
                <Box className={classes.tradeMarkContainerSmall}>
                  <Typography className={classes.tradeMarkText}>
                    ©2024 Demiplane
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.bottomBarSmallLineTwo}>
                <Box className={classes.supportContainerSmall}>
                  <Link
                    href={'https://www.demiplane.com/privacy-policy'}
                    prefetch={false}
                    className={classes.supportText}
                    target={'_blank'}
                    rel='noreferrer'
                  >
                    Privacy Policy
                  </Link>
                  <Link
                    href={'https://www.demiplane.com/terms-of-service'}
                    prefetch={false}
                    target={'_blank'}
                    className={classes.supportText}
                    rel='noreferrer'
                  >
                    Terms of Service
                  </Link>
                  <Link
                    href={'https://www.demiplane.com/code-of-conduct'}
                    prefetch={false}
                    target={'_blank'}
                    className={classes.supportText}
                    rel='noreferrer'
                  >
                    Code of Conduct
                  </Link>
                </Box>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingBottom: '2rem',
                }}
              >
                <Box className={classes.legal}>{config.legal}</Box>
              </Box>
            </Box>
          </Box>
        )}
      </ThemeProvider>
    </>
  );
};

export const NexusFooter = NexusFooterComp;
