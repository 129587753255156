import { NextRouter } from 'next/router';

export const useCheckLogin = (router: NextRouter, userId?: string) => {
  const checkLogin = () => {
    if (router.query.login && !!userId) {
      window.dataLayer?.push({
        event: 'login',
        user_id: userId,
      });
    }
  };

  return { checkLogin };
};
